<template>
    <nav class="environment-selection">
        <!-- TODO: translate -->
        <h3 class="title">
            Select your space
        </h3>
        <div class="environment-selection-list">
            <button
                v-for="environment in environments"
                :key="environment.uid"
                :ref="`environment-${environment.uid}`"
                :class="{
                    'selected': environment.uid === selectedUid
                }"
                class="environment-selection-item"
                @click="onClick(environment)"
            >
                <img :alt="environment.title" :src="environment.thumbnail">
                <span class="caption">{{ environment.title }}</span>
            </button>
        </div>
    </nav>
</template>

<script lang="ts">
import type {PropType} from 'vue';
import {defineComponent} from 'vue';
import type {EnvironmentModel3DAsset} from '@/Models/Asset/Assets/EnvironmentModel3DAsset';

export default defineComponent({
    props: {
        environments: {
            type: Array as PropType<EnvironmentModel3DAsset[]>,
            required: true
        },
        selectedUid: {
            type: String as PropType<string | undefined | null>,
            required: false,
            default: null,
        }
    },

    emits: [
        'selected'
    ],

    watch: {
        selectedUid(selectedUid: string) {
            const listItem = (this.$refs[`environment-${selectedUid}`] as HTMLLIElement[])[0];
            listItem.scrollIntoView({ behavior: 'smooth' });
        }
    },

    methods: {
        onClick(environment: EnvironmentModel3DAsset) {
            this.$emit('selected', environment);
        }
    }
});
</script>

<style lang="css" scoped>

.environment-selection {
    margin: 24px;
    max-width: min(896px, calc(100% - 32px));

    background-color: white;
    border-radius: var(--card-border-radius);

    animation: modal-opened .15s;

    .title {
        margin: 12px 16px 10px 16px;
    }

    .environment-selection-list {
        display: flex;
        align-self: center;
        padding: 0 16px 16px 16px;

        gap: 16px;
        list-style-type: none;

        overflow: scroll;
        scroll-snap-type: x mandatory;

        .environment-selection-item {
            padding: 0;
            border: none;
            background: none;
            scroll-snap-align: center;
            flex-basis: 160px;
            flex-shrink: 0;
            flex-grow: 0;
            text-align: start;

            .caption {
                margin-top: 4px;
                display: -webkit-box;
                font-size: var(--font-size-small);
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            img {
                width: 100%;
                border-radius: 8px;
                transition: border-color 0.1s;
                border: 2px solid transparent;
            }

            &.selected {
                img {
                    border-color: var(--color-primary);
                }
            }

            &:hover,
            &:focus-visible {
                img {
                    border-color: var(--color-primary-hover);
                }
            }
        }
    }
}
</style>
