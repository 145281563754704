import {parseDate} from '@/Utility/Helpers';
import AbstractDataObject from '@/Models/AbstractDataObject';
import Asset from '@/Models/Asset/Asset';
import User from '@/Models/User/User';
import UnitData from '@/Models/UnitData/UnitData';
import UnitType from '@/Models/UnitData/UnitType';
import Unit from "@/Models/Unit/Unit";
import AssetFactory from '@/Models/Asset/AssetFactory';

export default class UnitRevision extends AbstractDataObject {
    static get constructorName() {
        return 'UnitRevision';
    }

    public readonly uid: string;

    /**
     * Preview image URL
     */
    public readonly preview: string;

    /**
     * Preview image thumbnail URL
     */
    public readonly preview_thumbnail: string;

    /**
     * User ID of the associated owner
     */
    public readonly owned_by: string | null;

    /**
     * Associated owner User model
     */
    public readonly owner: User | null;


    public readonly created_at: Date;
    public readonly updated_at: Date;
    public readonly fetched_at: Date;
    public readonly released_at: Date | null;

    /**
     * Unique ID of the parent unit
     */
    public readonly unit_uid: string;

    /**
     * Unit type (e.g. 'VR', 'AR')
     */
    public readonly type: string;

    public readonly title: string;
    public readonly description: string | null;
    public readonly version: string;
    public readonly simplified_editor: boolean;

    /**
     * Compatible with the requested WMS?
     */
    public readonly compatible: boolean;

    /**
     * Platform from which this revision was created
     */
    public readonly platform: string;

    public readonly assets: { [key: string]: Asset };
    public readonly unit_data: UnitData;

    private preview_upload_file: File | null = null;

    constructor(attributes: any, parent: Unit) {
        super(parent);

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        [
            'preview_upload_file',
        ].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        // Populate the model:
        this.uid = attributes.uid;
        this.preview = attributes.preview;
        this.preview_thumbnail = attributes.preview_thumbnail;
        this.owned_by = attributes.owned_by || null;
        this.owner = (attributes.owner) ? new User(attributes.owner) : null;

        this.created_at = parseDate(attributes.created_at)!;
        this.updated_at = parseDate(attributes.updated_at)!;
        this.fetched_at = parseDate(attributes.fetched_at)!;
        this.released_at = parseDate(attributes.released_at || null);

        this.unit_uid = attributes.unit_uid;
        this.type = attributes.type || UnitType.VR.type;
        this.title = attributes.title;
        this.description = attributes.description || null;
        this.version = attributes.version;
        this.simplified_editor = attributes.simplified_editor;
        this.compatible = attributes.compatible;
        this.platform = attributes.platform;

        this.assets = Object.keys(attributes.assets || {}).reduce((assets, assetUid) => ({
            ...assets,
            [assetUid]: AssetFactory.createFromAttributes(attributes.assets[assetUid])
        }), {});
        // @NOTE: Has to be initialized AFTER the assets!
        this.unit_data = new UnitData(attributes.unit_data || {}, this);
    }

    /**
     * Clean up data (e.g. remove empty components from objects)
     * @returns {Boolean} true if anything was changed, false otherwise
     */
    cleanUpData(): boolean {
        return this.unit_data.cleanUpData();
    }

    /**
     * @returns true, if the unit revision compatible with the current WMS and LearningApp
     */
    get isCompatible(): boolean {
        return this.compatible;
    }

    /**
     * @returns true, if the unit revision in a draft state
     */
    get isDraft(): boolean {
        return this.released_at === null;
    }

    /**
     * @returns true, if this unit revision the latest revision of the parent unit
     */
    get isLatestRevision(): boolean {
        return this.getParent(Unit)?.latest_revision_uid === this.uid;
    }

    /**
     * @returns true, if this unit revision the latest released revision of the parent unit
     */
    get isLatestReleasedRevision(): boolean {
        return this.getParent(Unit)?.latest_released_revision_uid === this.uid;
    }

    /**
     * @returns true, if this unit revision has been released
     */
    get isReleased(): boolean {
        return this.released_at !== null;
    }

    /**
     * @returns true, if this revision's data is valid
     */
    get isValid(): boolean {
        return this.unit_data.isValid;
    }

    /**
     * @returns true, if the training does have the given asset assigned
     */
    hasAssetWithUid(uid: string): boolean {
        return this.assets[uid] instanceof Asset;
    }

    // TODO: Refactor: asset creation inside this method should not be needed
    addAssets(assets: Asset | Asset[]) {
        if (assets instanceof Asset) {
            return this.addAssets([assets]);
        }
        assets.filter(asset => typeof this.assets[asset.uid] === 'undefined' || this.assets[asset.uid].updated_at !== asset.updated_at).forEach(asset => {
            //console.info('UnitRevision->addAssets(): Adding new asset reference', asset.title);
            this.assets[asset.uid] = AssetFactory.createFromAttributes(asset);    // @NOTE: Creating a new instance to prevent accidentally modifying the reference
        });
        return this;
    }

    /**
     * Get preview image for uploading
     */
    get previewImageForUpload(): File | null {
        return this.preview_upload_file || null;
    }

    /**
     * Set preview image for uploading
     */
    set previewImageForUpload(file: File | null) {
        this.preview_upload_file = file;
    }
}
