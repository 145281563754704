<template>
    <SimplifiedEditor v-if="simplifiedEditor" :initial-unit-uid="initialUnitUid" />
    <Authoring v-else :initial-unit-uid="initialUnitUid" />
</template>

<script lang="ts">
import {defineAsyncComponent, defineComponent} from 'vue';
import SimplifiedEditor from '@/Vue/Units/SimplifiedEditor/SimplifiedEditor.vue';

export default defineComponent({
    components: {
        SimplifiedEditor,
        
        // Load authoring async - this causes vite/rollup to create separate bundled with these dependencies.
        // This will reduce load sizes for learners drastically.
        Authoring: defineAsyncComponent(() => import('@/Vue/Authoring/Authoring.vue')),
    },

    props: {
        /**
         * ID of the initially selected unit
         */
        initialUnitUid: {
            type: String,
            required: true
        },
        simplifiedEditor: {
            type: Boolean,
            required: true
        },
    },
});
</script>

<style lang="css" scoped>

</style>
