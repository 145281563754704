import type UnitRevision from '@/Models/Unit/UnitRevision';
import type {EnvironmentModel3DAsset} from '@/Models/Asset/Assets/EnvironmentModel3DAsset';
import SceneObject, {SceneObjectAssetEnvironmentModel3D} from '@/Models/UnitData/SceneObjects/SceneObject';
import type TrainingScene from '@/Models/UnitData/Scenes/TrainingScene';
import SceneObjectType from '@/Models/UnitData/SceneObjects/SceneObjectType';
import Unit from '@/Models/Unit/Unit';

export default class SimplifiedSoftSkillUnitRevision {

    private readonly firstScene: TrainingScene;

    constructor(private unitRevision: UnitRevision) {
        this.firstScene = unitRevision.unit_data.scenes[0];
    }

    get parentUnit(): Unit {
        return this.unitRevision.getParent(Unit)!;
    }

    get revision(): UnitRevision {
        return this.unitRevision;
    }

    get environment(): EnvironmentModel3DAsset | null {
        if (!this.firstScene.hasObjects) {
            return null;
        }
        return this.firstScene.objects.filter((asset): asset is SceneObjectAssetEnvironmentModel3D =>
            asset instanceof SceneObjectAssetEnvironmentModel3D
        )[0].asset;
    }

    set environment(environment: EnvironmentModel3DAsset) {
        const sceneObject = SceneObject.createWithType(
            SceneObjectType.Assets.EnvironmentModel3D,
            {
                title: environment.title,
                asset_uid: environment.uid,
                asset: environment,
            },
            this.firstScene
        );

        this.firstScene.objects = [sceneObject];
    }
}
