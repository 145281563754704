import {cloneDeep} from 'lodash';
import { updateUidReferences, getObjectUids, deleteUidReferences, getUidMappingFromDuplicatedObjects, trans, uuid4 } from '@/Utility/Helpers';
import AbstractDataObject from '@/Models/AbstractDataObject';
import SceneObject, { SceneObjectGroup } from '@/Models/UnitData/SceneObjects/SceneObject';
import SceneObjectives from '@/Models/UnitData/Scenes/Objectives/SceneObjectives';
import SceneObjectType from '@/Models/UnitData/SceneObjects/SceneObjectType';
import Trigger from '@/Models/UnitData/Triggers/Trigger';
import Transform from '@/Models/Unity/Transform';
import WaypointCollection from '@/Models/Unity/WaypointCollection';
import ComponentUtility from '@/Models/Unity/Components/ComponentUtility';
import UnitData from "@/Models/UnitData/UnitData";

export default class TrainingScene extends AbstractDataObject
{
    static get constructorName() { return 'TrainingScene'; }

    /**
     * Constructor
     *
     * @param {Object} attributes                  // Properties data
     * @param {AbstractDataObject | null} parent   // Parent object reference
     */
    constructor(attributes = {}, parent = null)
    {
        super(parent);

        // Clone the incoming data to avoid manipulation of variable references in memory:
        attributes = (attributes instanceof Object && attributes instanceof Array === false) ? attributes : {};
        let attrs = cloneDeep(attributes);

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        // @NOTE: Don't use any of the parent's properties in this (or any child) constructor as they may not exist (be undefined) yet!
        ['originalUid', 'selected', 'order'].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        // Convert child elements into their respective class instances:
        if (attrs.objects instanceof Array)
        {
            for (let soIndex in attrs.objects)
            {
                if (typeof attrs.objects[soIndex].type === 'string' && SceneObjectType.isValidType(attrs.objects[soIndex].type) === true)
                {
                    attrs.objects[soIndex] = SceneObject.createFromAttributes(attrs.objects[soIndex], this);
                }
                else
                {
                    console.warn('TrainingScene->constructor(): Missing or invalid scene object type. Data will be discarded when saving', attrs.objects[soIndex]);
                    attrs.objects[soIndex] = null;
                }
            }
            attrs.objects = attrs.objects.filter(o => o instanceof SceneObject);
        }

        // Populate the model:
        this.uid = attributes.uid || uuid4();                                   // Unique ID
        this.originalUid = this.uid;                                            // Original unique ID from which the object was duplicated (hidden)
        this.title = attributes.title || null;                                  // Title text
        this.description = attributes.description || null;                      // Description text
        /**
         * @type {SceneObject[]}
         */
        this.objects = attrs.objects || [];                                     // List of SceneObjects
        this.components = (attributes.components || []).map(c => ComponentUtility.castComponent(c)); // Unity components created by the DreamApp
        this.triggers = (attributes.triggers || []).map(t => Trigger.createFromAttributes(t, this)); // List of triggers
        this.objectives = new SceneObjectives(attributes.objectives || {}, this); // SceneObjectives object
        this.selected = (typeof attributes.selected === 'boolean') ? attributes.selected : false;  // Selected state (hidden)
        this.monoscopic = (typeof attributes.monoscopic === 'boolean') ? attributes.monoscopic : false; // Monoscopic camera
        this.teleport_disabled = (typeof attributes.teleport_disabled === 'boolean') ? attributes.teleport_disabled : false; // Whether teleporting is disabled

        // Generate the order of scene objectives from the given scene objects (triggers):
        this.updateObjectives();
    }

    /**
     * Create a new TrainingScene from given attributes
     *
     * @param {Object} attributes
     * @param {Object} parent
     * @returns {TrainingScene}
     */
    static createFromAttributes(attributes = {}, parent = null)
    {
        // Clone the incoming data to avoid manipulation of variable references in memory:
        const clonedAttributes = (attributes instanceof Object) ? cloneDeep(attributes) : new Object(null);
        return new TrainingScene(clonedAttributes, parent);
    }

    /** @inheritdoc */
    get clipboardTitle()
    {
        const translatedTitle = trans('labels.scene');
        return translatedTitle + (this.title && this.title !== translatedTitle ? ` "${this.title}"` : '');
    }

    /**
     * Does the scene have any objects?
     *
     * @returns {Boolean}
     */
    get hasObjects() {
        return (this.objects instanceof Array && this.objects.length > 0);
    }

    /**
     * Does the scene have a specific object?
     *
     * @returns {Boolean}
     */
    hasObject(object) {
        return (object !== null && this.hasObjects && this.objects.find(o => Object.is(o, object) === true || (o instanceof SceneObject && o.uid === object.uid) || (o instanceof SceneObjectGroup && o.hasObject(object))) !== undefined);
    }

    /**
     * Get the total count of scene objects (including nested objects)
     *
     * @returns {Number}
     */
    get objectsCount() {
        if (this.hasObjects)
        {
            return this.objects.length + this.objects.filter(c => c instanceof SceneObjectGroup).map(c => c.objectsCount).reduce((a, b) => a + b, 0);
        }
        return 0;
    }

    /**
     * Get the total count of global scene objects (including non-collapsed nested objects)
     *
     * @returns {Number}
     */
    get objectsCountCollapsed() {
        return !this.hasObjects ? 0 : this.objects.map(c => 1 + (c instanceof SceneObjectGroup ? c.objectsCountCollapsed : 0)).reduce((count, c) => count + c, 0);
    }

    /**
     * Does the scene have any components?
     *
     * @returns {Boolean}
     */
    get hasComponents() {
        return (this.components instanceof Array && this.components.length > 0);
    }

    /**
     * Does the scene have any triggers?
     *
     * @returns {Boolean}
     */
    get hasTriggers() {
        return (this.triggers instanceof Array && this.triggers.length > 0);
    }

    /**
     * Get all triggers from the scene and its nested objects as a flattened list
     *
     * @returns {Trigger[]}
     */
    get allTriggers() {
        return [this].concat(this.allSceneObjects).filter(o => o.hasTriggers).map(o => o.triggers).flat();
    }

    /**
     * Get all nested scene objects from the scene as a flattened list
     *
     * @returns {SceneObject[]}
     */
    get allSceneObjects() {
        return (this.objects || []).map(o => [o, o.objectsFlat || []]).flat(2);
    }

    /**
     * Check if the object is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        // All objects, objectives and triggers must be valid:
        return this.objectives.isValid && this.objects.every(c => c.isValid) && this.triggers.every(c => c.isValid);
    }

    /**
     * Get the scene's index/order formatted for displaying in the UI
     *
     * @returns {String}
     */
    get indexFormatted() {
        return `${this.order + 1}`.padStart(2, '0');
    }

    /**
     * Get the scene's index/order and title formatted for displaying in the UI
     *
     * @returns {String}
     */
    get indexAndTitleFormatted() {
        return `${this.indexFormatted} - ${this.title}`;
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {TrainingScene}
     */
    duplicate(updateUidMapping = true) {
        const duplicated = new TrainingScene(this, this.parent);
        duplicated.uid = uuid4();
        duplicated.selected = false;
        // @TODO: Set dates?

        // Create new instances for child objects:
        duplicated.components = duplicated.components.map(c => typeof c.duplicate === 'function' ? c.duplicate(false) : cloneDeep(c));
        duplicated.objects = duplicated.objects.map(o => o.duplicate(false));
        duplicated.objectives = (this.objectives !== null) ? this.objectives.duplicate(false) : null;
        duplicated.objectives.parent = duplicated;
        duplicated.triggers = duplicated.triggers.map(t => t.duplicate(false));

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping === true) {updateUidReferences(duplicated);}

        // Duplicate properties on global objects:
        const parentUnitData = duplicated.getParent(UnitData);
        if (parentUnitData === null)
        {
            console.warn('TrainingScene->duplicate(): Unable to update global object references because parent UnitData is not set');
            return duplicated;
        }
        const globalObjects = parentUnitData.allGlobalObjects;
        globalObjects.forEach(o => {

            // Hidden in scenes:
            if (o.hidden_in_scenes instanceof Array && o.hidden_in_scenes.includes(this.uid))
            {
                o.hidden_in_scenes.push(duplicated.uid);
            }

            // Duplicate waypoints:
            if (o instanceof SceneObject && o.waypoints instanceof WaypointCollection && o.waypoints.hasWaypointsForScene(this))
            {
                o.waypoints.duplicateWaypointsForNewScene(this, duplicated);
            }

            // Duplicate hints:
            if (typeof o.duplicateHintsForNewScene === 'function')
            {
                o.duplicateHintsForNewScene(duplicated);
            }
        });

        // Update mapping for duplicated global objects (primarily waypoints):
        const parentUidMapping = getUidMappingFromDuplicatedObjects(globalObjects);
        updateUidReferences(duplicated, parentUidMapping);

        return duplicated;
    }

    /**
     * Get triggers sorted by type
     *
     * @returns {Object<Trigger[]>}
     */
    getTriggersSortedByType() {
        const triggers = {};
        TriggerType.all.forEach(type => {
            triggers[type.type] = [];
        });
        this.triggers.forEach(t => {
            triggers[t.event].push(t);
        });
        return triggers;
    }

    /**
     * Get components by specific type
     *
     * @param {String} componentType
     * @returns {Component[]}
     */
    getComponentsByType(componentType)
    {
        return (this.components.length > 0) ? this.components.filter(c => typeof c.type === 'string' && c.type === componentType) : [];
    }

    /**
     * Clean up data (e.g. remove empty components from objects)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = false;
        if (this.objectives.cleanUpData()) {hasChanged = true;}
        if (this.hasObjects && this.objects.filter(o => o instanceof SceneObject && o.cleanUpData()).length > 0) {hasChanged = true;}
        if (this.hasTriggers && this.triggers.filter(t => t instanceof Trigger && t.cleanUpData()).length > 0) {hasChanged = true;}
        if (this.hasComponents)
        {
            const componentsCount = this.components.length;
            this.components = ComponentUtility.removeEmptyComponentsFromArray(this.components);
            if (componentsCount !== this.components.length)
            {
                // console.info('TrainingScene->cleanUpData(): Removing empty components.', this);
                hasChanged = true;
            }
        }
        return hasChanged;
    }

    /**
     * Reset transforms on all scene objects
     */
    resetTransforms() {
        if (this.objects instanceof Array && this.objects.length > 0)
        {
            this.objects.filter(o => o instanceof SceneObject).forEach(o => o.resetTransform());
            this.objects.filter(o => o instanceof SceneObjectGroup).forEach(o => o.resetTransforms());
        }
        // Reset transforms on components:
        if (this.components.length > 0)
        {
            this.components.filter(c => c instanceof Object && c.transform instanceof Transform).forEach(c => c.transform = new Transform());
        }
        return this;
    }

    /**
     * Add a given object to this scene
     *
     * @param {SceneObject} sceneObject         // The SceneObject to be inserted
     * @param {Number} index                    // Optional index at which the new object should be inserted
     * @returns {SceneObject|null}              // The successfully added SceneObject, otherwise null
     */
    addSceneObject(sceneObject, index = null) {

        // Set parent first since it's needed for hasReachedMaxCount and has to be set anyway when inserting:
        sceneObject.parent = this;

        // Cancel if the object's type is not allowed:
        if (sceneObject.hasReachedMaxCount) {return null;}

        // Do not insert forbidden objects in group:
        if (sceneObject instanceof SceneObjectGroup && sceneObject.hasObjects)
        {
            sceneObject.objects = sceneObject.objects.filter(so => !so.hasReachedMaxCount);
        }

        // Insert the object:
        this.objects.splice((typeof index === 'number' && index >= 0) ? index : this.objects.length, 0, sceneObject);

        // Clean up data:
        this.cleanUpData();

        // Update the objectives:
        this.updateObjectives();

        return sceneObject;
    }

    /**
     * Remove a given object from this scene
     *
     * @NOTE: Since removeSceneObject is recursive, the UID references must only be deleted from the parent-most object!
     *        Any calls to removeSceneObject() on child elements therefore must use false for the deleteUidReferences parameter!
     *
     * @param {SceneObject} sceneObject
     * @param {Boolean} deleteReferences
     */
    removeSceneObject(sceneObject, deleteReferences = true) {

        // Get UIDs from the object and its children:
        const uids = (deleteReferences === true) ? getObjectUids(sceneObject) : [];

        // Remove object from the list of objects:
        this.objects = this.objects.filter(o => Object.is(o, sceneObject) === false || o.uid !== sceneObject.uid);
        this.objects.filter(o => o instanceof SceneObjectGroup).map(o => o.removeSceneObject(sceneObject, false));

        // Delete all UID references across the unit or this object:
        if (deleteReferences === true)
        {
            deleteUidReferences(this.getParent(UnitData) || this, uids);
        }

        // Update the objectives:
        this.updateObjectives();

        return this;
    }

    /**
     * Update objectives
     */
    updateObjectives() {
        if (this.objectives !== null)
        {
            this.objectives.updateOrderFromParentScene();
        }
        return this;
    }
}
