<template>
    <main id="layout-main" :data-loading="isLoading" :data-saving="isSaving">
        <header class="button-header">
            <!-- TODO: move to translation -->
            <!-- TODO: add tooltip -->
            <!-- TODO: disable when loading -->
            <ButtonSecondary
                v-if="isEditMode"
                caption="Preview"
                icon="icon_play"
                @trigger="onPreviewClick"
            />
            <!-- TODO: move to translation -->
            <!-- TODO: add tooltip -->
            <!-- TODO: disable when loading -->
            <!-- TODO: change icon -->
            <ButtonSecondary
                v-if="!isEditMode"
                caption="Preview"
                icon="icon_close"
                @trigger="onLeavePreviewClick"
            />
            <!-- TODO: move to translation -->
            <!-- TODO: add tooltip -->
            <!-- TODO: disable when loading -->
            <ButtonPrimary
                caption="Done"
                icon="icon_check"
                @trigger="onSaveClick"
            />
        </header>

        <EnvironmentSelection
            v-if="isEditMode"
            :environments="environments"
            :selected-uid="currentEnvironment?.uid"
            class="environment-selection"
            @selected="onEnvironmentSelected"
        />

        <!-- TODO: add unity webapp -->
        <img :src="currentEnvironment?.preview" class="unity-placeholder">

        <ModalProgress />
    </main>
</template>

<script lang="ts">
import type {Ref} from 'vue';
import {defineComponent, inject} from 'vue';
import {trans} from '@/Utility/Helpers/trans';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import {assetServiceKey, unitServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import Unit from '@/Models/Unit/Unit';
import EventType from '@/Utility/EventType';
import AuthorizationError from '@/Errors/AuthorizationError';
import {EnvironmentModel3DAsset} from '@/Models/Asset/Assets/EnvironmentModel3DAsset';
import EnvironmentSelection from '@/Vue/Units/SimplifiedEditor/EnvironmentSelection.vue';
import SimplifiedSoftSkillUnitRevision from '@/Models/Unit/SimplifiedSoftSkillUnitRevision';

export default defineComponent({
    components: {
        EnvironmentSelection,
        ModalProgress,
        ButtonSecondary,
        ButtonPrimary,
    },

    props: {
        /**
         * ID of the initially selected unit
         */
        initialUnitUid: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            assetService: inject(assetServiceKey)!,
            unitService: inject(unitServiceKey)!,
            simplifiedRevision: null as Ref<SimplifiedSoftSkillUnitRevision> | null,
            isEditMode: true,
        };
    },

    computed: {
        environments(): EnvironmentModel3DAsset[] {
            return this.assetService.assets.filter(asset => asset instanceof EnvironmentModel3DAsset);
        },

        currentEnvironment(): EnvironmentModel3DAsset | null {
            return this.simplifiedRevision?.environment || null;
        },

        isLoading(): boolean {
            if (this.unitService.isLoading || this.assetService.isLoading) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        isSaving(): boolean {
            if (this.unitService.isSaving || this.assetService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },
    },

    mounted() {
        Promise.all([
            this.fetchAssets(),
            this.fetchUnit(),
        ]);
    },

    methods: {
        trans,

        onEnvironmentSelected(environment: EnvironmentModel3DAsset) {
            this.updateEnvironment(environment);
        },

        updateEnvironment(environment: EnvironmentModel3DAsset) {
            if (!this.simplifiedRevision) {
                return;
            }

            this.simplifiedRevision.environment = environment;
        },

        onSaveClick() {
            if (!this.simplifiedRevision) {
                return;
            }

            this.unitService
                .updateUnit(this.simplifiedRevision.parentUnit, this.simplifiedRevision.revision)
                .then(unit => this.unitService.releaseUnit(unit))
                .then(unit => this.setUnit(unit))
                .catch(this.onErrorApi);
        },

        onPreviewClick() {
            this.isEditMode = false;
        },

        onLeavePreviewClick() {
            this.isEditMode = true;
        },

        async fetchAssets() {
            return this.assetService
                .fetchAssets()
                .catch(this.onErrorApi);
        },

        async fetchUnit() {
            return this.unitService
                .fetchUnit(new Unit({ uid: this.initialUnitUid }))
                .then(unit => this.setUnit(unit))
                .catch(this.onErrorApi);
        },

        setUnit(unit: Unit) {
            this.simplifiedRevision = new SimplifiedSoftSkillUnitRevision(unit.latestRevision!);
        },

        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = () => {
                    this.$root!.forceLogout();
                };
            }
            this.$root!.showErrorDialog(error);
        },
    },
});
</script>

<style lang="css" scoped>

#layout-main {
    display: grid;

    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "header"
        "empty"
        "bottom";

    .button-header {
        grid-area: header;

        display: flex;
        justify-content: end;
        align-items: center;
        gap: 16px;
        padding: 5px 32px 0;
        height: var(--header-height);

        /** TODO: improve button styling (white we do not have yet) */

        .btn-secondary,
        .btn-secondary:hover {
            background-color: white;
        }
    }

    .environment-selection {
        grid-area: bottom;
        justify-self: center;
    }

    .unity-placeholder {
        position: absolute;
        z-index: -1;
        inset: 0;
        background-color: grey;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

</style>
