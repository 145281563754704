import {
    compareAlphabetical,
    deleteUidReferences,
    getObjectUids,
    uuid4
} from '@/Utility/Helpers';
import Tags from "@/Utility/Tags";
import AbstractDataObject from '@/Models/AbstractDataObject';
import CommandType from '@/Models/UnitData/Commands/CommandType';
import SceneObject, {SceneObjectGroup} from '@/Models/UnitData/SceneObjects/SceneObject';
import SceneObjectType from '@/Models/UnitData/SceneObjects/SceneObjectType';
import TrainingConfiguration from '@/Models/UnitData/Configuration/TrainingConfiguration';
import TrainingScene from '@/Models/UnitData/Scenes/TrainingScene';
import UnitType from '@/Models/UnitData/UnitType';

export default class UnitData extends AbstractDataObject
{
    static get constructorName() { return 'UnitData'; }

    /**
     * Constructor
     *
     * @param {Object} attributes                 // Properties data
     * @param {AbstractDataObject | null} parent  // Parent object reference
     */
    constructor(attributes = {}, parent = null)
    {
        super(parent);

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        [
            'scenes_count',
        ].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        // Populate the model:
        this.uid = attributes.uid || uuid4();                                   // Unique ID (same as the revision UID)
        this.type = attributes.type || UnitType.VR.type;                        // Unit type (e.g. 'VR', 'AR')
        this.version = attributes.version || null;                              // Version
        this.simplified_editor = attributes.simplified_editor || false;

        this.title = attributes.title || null;                                  // Title text
        this.description = attributes.description || null;                      // Description text

        this.configuration = new TrainingConfiguration(attributes.configuration || null);   // Additional configuration object (TrainingConfiguration)

        this.spatial_positioning = (this.type === UnitType.AR.type) ? true : (typeof attributes.spatial_positioning === 'boolean') ? attributes.spatial_positioning : false;  // Spatial positioning
        this.network_enabled = (typeof attributes.network_enabled === 'boolean') ? attributes.network_enabled : false;  // Networking state

        this.objects = (attributes.objects || []).map(o => SceneObject.createFromAttributes(o, this));    // List of global SceneObjects
        this.scenes = (attributes.scenes || []).map(s => new TrainingScene(s, this));   // List of scenes

        // Hidden and UI-related
        this.scenes_count = attributes.scenes_count || this.scenesCount;        // Count of scenes provided by the API (hidden)

        this.updateScenesOrder();
    }

    /**
     * Create a new UnitRevision from given attributes
     *
     * @param {Object} attributes
     * @param {Object} parent
     * @returns {UnitRevision}
     */
    static createFromAttributes(attributes = {}, parent = null) {
        return new (this)(...arguments);
    }

    /**
     * Create a new global helper module if none exists yet
     *
     * @returns {SceneObjectModuleHelper|null}
     */
    createHelperModule() {
        const helperModule = SceneObject.createWithType(SceneObjectType.Modules.Helper, {}, null);
        return this.addSceneObject(helperModule, null);
    }

    /**
     * Does the training have any objects?
     *
     * @returns {Boolean}
     */
    get hasObjects() {
        return (this.objects instanceof Array && this.objects.length > 0);
    }

    /**
     * Does the revision have a specific global object?
     *
     * @param {SceneObject} object
     * @returns {Boolean}
     */
    hasObject(object) {
        return (
            object !== null &&
            this.hasObjects &&
            this.objects.find(o => Object.is(o, object) === true || (
                    o instanceof SceneObject &&
                    o.uid === object.uid
                ) || (
                    o instanceof SceneObjectGroup &&
                    o.hasObject(object)
                )
            ) !== undefined
        );
    }

    /**
     * Look for a specific scene object with a given UID
     *
     * @param {String} uid
     * @returns {SceneObject|Null}
     */
    findSceneObjectByUid(uid) {
        return this.allSceneObjects.find(o => o.uid === uid) || null;
    }

    /**
     * Get all commands from the unit and its scenes as a flattened list
     *
     * @returns {Command[]}
     */
    get allCommands() {
        const objectivesCommands = this.scenes.filter(s => s.objectives !== null && s.objectives.hasCommands).map(s => s.objectives.commands);
        const triggerCommands = this.allTriggers.filter(t => t.hasCommands).map(t => t.commands);
        const contentCommands = this.allSceneObjects.filter(s => s.hasContents).map(s => s.contents).flat().map(c => c.buttons).flat().map(b => b.commands).flat();
        const combinedCommands = [...objectivesCommands, ...triggerCommands, ...contentCommands].flat();
        return combinedCommands.concat(combinedCommands.filter(c => c.hasCommands).map(c => c.commands).flat());
    }

    /**
     * Get all nested global scene objects from the unit as a flattened list
     *
     * @returns {SceneObject[]}
     */
    get allGlobalObjects() {
        return (this.objects || []).map(o => [o, o.objectsFlat || []]).flat(2);
    }

    /**
     * Get all nested scene objects from the unit and its scenes as a flattened list
     *
     * @returns {SceneObject[]}
     */
    get allSceneObjects() {
        return this.allGlobalObjects.concat((this.scenes || []).map(s => s.allSceneObjects)).flat(2);
    }

    /**
     * Get all nested triggers from the unit and its scenes as a flattened list
     *
     * @returns {Trigger[]}
     */
    get allTriggers() {
        return this.allGlobalObjects.filter(o => o.hasTriggers).map(o => o.triggers).concat(this.scenes.map(s => s.allTriggers)).flat();
    }

    /**
     * Get all (nested) objects that need an enabled feature flag to function.
     *
     * @returns {AbstractDataObject[]}
     */
    get dataObjectsInNeedOfEntitlement() {
        return []
            .concat(this.allSceneObjects, this.allCommands, this.allTriggers)
            .filter(dataObject => dataObject.entitlementsNeeded.length > 0);
    }

    /**
     * @returns {Feature[]}
     */
    get entitlementsNeeded() {
        return Array.from(new Set(this.dataObjectsInNeedOfEntitlement.flatMap(dataObject => dataObject.entitlementsNeeded)));
    }

    /**
     * Get the first Helper module from the unit and its scenes
     *
     * @returns {SceneObject|Null}
     */
    get firstHelperModule() {
        // @NOTE: Should be allSceneObjects if we ever allow helper modules on scenes again
        return this.allGlobalObjects.find(o => o.typeOf(SceneObjectType.Modules.Helper)) || null;
    }

    /**
     * Check if the revision is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        // All objects and scenes must be valid:
        return this.objects.every(c => c.isValid) && this.scenes.every(c => c.isValid);
    }

    /**
     * Get the total count of global scene objects (including nested objects)
     *
     * @returns {Number}
     */
    get objectsCount() {
        if (this.hasObjects) {
            return this.objects.length + this.objects.filter(c => c instanceof SceneObjectGroup).map(c => c.objectsCount).reduce((a, b) => a + b, 0);
        }
        return 0;
    }

    /**
     * Get the total count of global scene objects (including non-collapsed nested objects)
     *
     * @returns {Number}
     */
    get objectsCountCollapsed() {
        return !this.hasObjects ? 0 : this.objects.map(c => 1 + (c instanceof SceneObjectGroup ? c.objectsCountCollapsed : 0)).reduce((count, c) => count + c, 0);
    }

    /**
     * Does the training have any training scenes?
     *
     * @returns {Boolean}
     */
    get hasScenes() {
        return (this.scenesCount > 0);
    }

    /**
     * Get the count of training scenes
     *
     * @returns {Number}
     */
    get scenesCount() {
        return (this.scenes instanceof Array) ? this.scenes.length : 0;
    }

    /**
     * Get the maximum count of scenes that a training can have
     *
     * @NOTE: Make sure to also set this in Training.php
     *
     * @returns {Number}
     */
    get scenesCountLimit() {
        return 99;
    }

    /**
     * Get the (first) selected scene
     *
     * @returns {TrainingScene|null}
     */
    get selectedScene() {
        if (this.hasScenes === true) {
            return this.scenes.find(s => s.selected === true) || null;
        }
        return null;
    }

    /**
     * Get all selected scenes
     *
     * @returns {TrainingScene[]}
     */
    get selectedScenes() {
        if (this.hasScenes === true) {
            return this.scenes.filter(s => s.selected === true);
        }
        return [];
    }

    /**
     * Get all deselected scenes
     *
     * @returns {TrainingScene[]}
     */
    get deselectedScenes() {
        if (this.hasScenes === true) {
            return this.scenes.filter(s => s.selected === false);
        }
        return [];
    }

    /**
     * Add a new scene
     *
     * @param {TrainingScene} scene         // The TrainingScene object to be added
     * @param {Number} insertAtIndex        // Optional index at which the new scene should be inserted
     * @returns {TrainingScene|null}        // The successfully added TrainingScene, otherwise null
     */
    addNewScene(scene, insertAtIndex = null) {

        // Cancel if the maximum number of scenes is reached already:
        if (this.scenesCount >= this.scenesCountLimit) {
            return null;
        }

        // Set parent and insert the scene:
        scene.parent = this;
        this.scenes.splice((typeof insertAtIndex === 'number' && insertAtIndex >= 0) ? insertAtIndex : this.scenesCount, 0, scene);

        // Manually update the scenes count that was returned from the API:
        this.scenes_count = this.scenesCount;

        // Update the order for all scenes:
        this.updateScenesOrder();

        // Clean up data:
        this.cleanUpData();

        return scene;
    }

    /**
     * Duplicate a scene within this revision
     *
     * @param {TrainingScene} scene
     * @param {String} newTitle
     */
    duplicateScene(scene, newTitle = null) {
        if (this.hasScenes === false) {
            return this;
        }
        const sceneIndex = this.scenes.findIndex(s => s.uid === scene.uid);
        const sceneToDuplicate = (sceneIndex === -1) ? null : this.scenes[sceneIndex];
        if (sceneToDuplicate instanceof TrainingScene) {
            const duplicatedScene = sceneToDuplicate.duplicate(true);
            duplicatedScene.title = newTitle || duplicatedScene.title;
            this.scenes.splice(sceneIndex + 1, 0, duplicatedScene);
            this.updateScenesOrder();
            this.cleanUpData();
        }
        return this;
    }

    /**
     * Remove a given scene from the revision
     *
     * @param {TrainingScene} scene
     */
    removeScene(scene) {
        if (this.hasScenes === false) {
            return this;
        }
        const sceneIndex = this.scenes.findIndex(s => s.uid === scene.uid);
        if (sceneIndex >= 0) {

            // Get UIDs from all objects in the scene and the scene itself:
            const uidsInScene = getObjectUids(this.scenes[sceneIndex]);

            // Remove the scene:
            this.scenes[sceneIndex] = null;
            this.scenes.splice(sceneIndex, 1);

            // Delete all UID references across the entire unit:
            deleteUidReferences(this, uidsInScene);

            // Update the order for all scenes:
            this.updateScenesOrder();
        }
        // Manually update the scenes count that was returned from the API:
        this.scenes_count = this.scenesCount;
        return this;
    }

    /**
     * Deselect a specific scene
     *
     * @param {TrainingScene} scene
     */
    deselectScene(scene) {
        return this.selectScene(scene, false);
    }

    /**
     * Deselect all scenes
     */
    deselectScenes() {
        if (this.hasScenes === true) {
            this.scenes.forEach(s => s.selected = false);
        }
        return this;
    }

    /**
     * Select a specific scene
     *
     * @param {TrainingScene} scene
     * @param {Boolean} selected
     */
    selectScene(scene, selected = true) {
        if (this.hasScenes === false) {
            return this;
        }
        if (scene instanceof TrainingScene) {
            scene.selected = selected;
            const sceneFromRevision = this.scenes.find(s => s.uid === scene.uid) || null;
            if (sceneFromRevision) {
                sceneFromRevision.selected = selected;
            }
        }
        return this;
    }

    /**
     * Change the training's type
     *
     * @param {UnitType} unitType
     * @param {Boolean} keepAssetLocations
     */
    changeType(unitType, keepAssetLocations = true) {
        this.type = unitType.type;

        // Set spatial positioning:
        this.spatial_positioning = (unitType.type === UnitType.AR.type) ? true : this.spatial_positioning;

        // Reset asset transforms:
        if (keepAssetLocations === false) {
            this.resetTransforms();
        }

        // Set scenes to monoscopic for 360 trainings:
        if (unitType.type === UnitType.ThreeSixty.type && this.hasScenes === true) {
            this.scenes.forEach(s => s.monoscopic = true);
        }

        // Disable monoscopic for AR trainings:
        else if (unitType.type === UnitType.AR.type && this.hasScenes === true) {
            this.scenes.forEach(s => s.monoscopic = false);
        }

        return this;
    }

    /**
     * Check if the training is of a given type
     *
     * @param {UnitType} unitType
     * @returns {Boolean}
     */
    typeOf(unitType) {
        return (unitType instanceof UnitType && this.type === unitType.type);
    }

    /**
     * Get tags from scene objects
     *
     * @returns {string[]}
     */
    getTagsFromSceneObjects() {
        const predefinedTags = Tags.all;
        const tags = this.allSceneObjects.map(
            o => (o.tags || []).concat(
                (o.triggers || []).map(t => t.tags || [])
            )
        ).flat(2).map(t => predefinedTags.find(pt => pt.toLowerCase() === t.toLowerCase()) || t);
        return [...new Set(tags)].sort(compareAlphabetical);
    }

    /**
     * Add a given object to this training
     *
     * @param {SceneObject} sceneObject         // The SceneObject to be inserted
     * @param {Number} index                    // Optional index at which the new object should be inserted
     * @returns {SceneObject|null}              // The successfully added SceneObject, otherwise null
     */
    addSceneObject(sceneObject, index = null) {

        // Set parent first since it's needed for hasReachedMaxCount and has to be set anyway when inserting:
        sceneObject.parent = this;

        // Cancel if the object's type is not allowed:
        if (sceneObject.hasReachedMaxCount) {
            return null;
        }

        // Do not insert forbidden objects in group:
        if (sceneObject instanceof SceneObjectGroup && sceneObject.hasObjects) {
            sceneObject.objects = sceneObject.objects.filter(so => !so.hasReachedMaxCount);
        }

        // Insert the object:
        this.objects.splice((typeof index === 'number' && index >= 0) ? index : this.objects.length, 0, sceneObject);

        // Clean up data:
        this.cleanUpData();

        return sceneObject;
    }

    /**
     * Remove a given object from this unit
     *
     * @NOTE: Since removeSceneObject is recursive, the UID references must only be deleted from the parent-most object!
     *        Any calls to removeSceneObject() on child elements therefore must use false for the deleteUidReferences parameter!
     *
     * @param {SceneObject} sceneObject
     * @param {Boolean} deleteReferences
     */
    removeSceneObject(sceneObject, deleteReferences = true) {

        // Get UIDs from the object and its children:
        const uids = (deleteReferences === true) ? getObjectUids(sceneObject) : [];

        // Remove object from the list of global objects:
        this.objects = this.objects.filter(o => Object.is(o, sceneObject) === false || o.uid !== sceneObject.uid);
        this.objects.filter(o => o instanceof SceneObjectGroup).map(o => o.removeSceneObject(sceneObject, false));

        // Remove object from scenes:
        this.scenes.forEach(s => s.removeSceneObject(sceneObject, false));

        // Delete all UID references across the entire unit:
        if (deleteReferences === true && uids.length > 0) {
            deleteUidReferences(this, uids);
        }

        return this;
    }

    /**
     * Clean up data (e.g. remove empty components from objects)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = false;
        if (this.hasScenes && this.scenes.filter(s => s instanceof TrainingScene && s.cleanUpData()).length > 0) {
            hasChanged = true;
        }
        if (this.hasObjects && this.objects.filter(o => o instanceof SceneObject && o.cleanUpData()).length > 0) {
            hasChanged = true;
        }
        return hasChanged;
    }

    /**
     * Reset transforms on all scenes
     */
    resetTransforms() {
        if (this.hasScenes) {
            this.scenes.filter(s => s instanceof TrainingScene).forEach(s => s.resetTransforms());
        }
        if (this.hasObjects) {
            this.objects.filter(o => o instanceof SceneObject).forEach(o => o.resetTransform());
            this.objects.filter(o => o instanceof SceneObjectGroup).forEach(o => o.resetTransforms());
        }
        return this;
    }

    /**
     * Update the order property for all scenes
     */
    updateScenesOrder() {
        // Set internal order property for all scenes (required for UI):
        this.scenes.forEach((scene, index) => scene.order = index);
        // Update related commands that use the scenes' indexes:
        this.allCommands.filter(c => c.type === CommandType.SceneChange.type).forEach(c => c.cleanUpData());
        return this;
    }
}
